import React, { useState, useEffect, useCallback } from "react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import { useStore } from "../store/index";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetchBalanceFromUser } from "utils/userBalanceFetch";
import { fetchBalanceFromFeePayer } from "utils/feePayerBalanceFetch";
import Logout from "../assets/icons/logout.svg";
import Copy from "../assets/icons/Icon-copy.svg";
import Switch from "../assets/icons/switch-vertical-02.svg";
import Deposit from "./Deposit";
import Container from "../assets/icons/container.svg";
import ChevronDownGreen from "../assets/icons/chevron-down-green.svg";
import ClickAwayListener from "react-click-away-listener";
import { addressSlice, copyAddress } from "utils/helpers";
import Kanalogo from "../assets/icons/KanaLogo-Mobile.svg";
import Bell from "../assets/icons/Bell-icon.svg";
import Menubar from "../assets/icons/menu-bar.svg";
import LeftArrowGreen from "../assets/icons/leftArrowGreen.svg";

export const wallets = [
  new PetraWallet(),
  new MartianWallet(),
] as any;

const Header = () => {
  const navigate = useNavigate();
  const { connected, disconnect, account, wallet, network, signMessage } =
    useWallet();

  const {
    updateBalance,
    balance,
    updateAddress,
    updateFeePayerBalance,
    updatePublicKey,
    feePayerAddresses,
    updatePopUpIsOpen,
    updateSignature,
    checkIsAdmin,
    setCheckIsAdmin,
    setChangeHeader,
    setDetails,
    changeHeader,
    updateDappDetails,
  } = useStore();
  const [address, setAddress] = useState<string>("");
  const [totalFeePayerBalances, setTotalFeePayerBalances] = useState<any>();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [walletnetwork, updateNetwork] = useState("");

  const handleConnectDisconnect = () => {
    if (connected) {
      disconnect();
      updateDappDetails("");
      setCheckIsAdmin(false);
      setChangeHeader(false);
      setDetails([]);
      updateSignature("");
    }
  };

  const fetchBalance = useCallback(async () => {
    if (account?.address) {
      updateAddress(account?.address as any);
      updatePublicKey(account?.publicKey as any);
      setAddress(account?.address as string);
      try {
        updateBalance(
          await fetchBalanceFromUser(
            account?.address as string,
            network?.name?.toLowerCase()
          )
        );
      } catch (error: any) {
        toast.error("Error while fetching balance");
      }
    }
  }, [
    account?.address,
    account?.publicKey,
    network?.name,
    updateAddress,
    updateBalance,
    updatePublicKey,
  ]);

  const fetchFeePayerBalance = useCallback(async () => {
    if (feePayerAddresses) {
      let totalFeePayerBalance = 0 as any;
      const individualBalances = [] as any[];

      for (const address of feePayerAddresses) {
        try {
          const feepayerBalance = await fetchBalanceFromFeePayer(
            address,
            network?.name?.toLowerCase()
          );
          totalFeePayerBalance += feepayerBalance;
          individualBalances.push({ address, balance: feepayerBalance });

          updateFeePayerBalance(individualBalances);
        } catch (error) {
          toast.error(`Error while fetching balance for address ${address}`);
        }
      }

      const balancesOnly = individualBalances.map((item: any) =>
        parseFloat(item.balance)
      );
      const totalBalance = balancesOnly.reduce(
        (acc, balance) => acc + balance,
        0
      );

      setTotalFeePayerBalances(totalBalance.toFixed(8));
    }
  }, [feePayerAddresses, network?.name, updateFeePayerBalance, address]);

  useEffect(() => {
    if (connected) {
      fetchBalance();
      fetchFeePayerBalance();
    }
  }, [
    connected,
    fetchBalance,
    balance,
    network?.name,
    account?.address,
    fetchFeePayerBalance,
    address,
  ]);

  useEffect(() => {
    if (
      address &&
      address !== "" &&
      address !== account?.address &&
      network?.name?.toLowerCase()
    ) {
      const payload = {
        message: "Kana Paymaster Dashboard Login",
        nonce: "Kanalabs",
      };

      // Sign the message
      signMessage(payload)
        .then((signature: any) => {
          updateSignature(signature?.signature);
        })
        .catch((error: any) => {
          disconnect();
        });
      if (checkIsAdmin) {
        navigate("/admin");
      }
      navigate("/dashboard");
    }
  }, [
    address,
    account?.address,
    navigate,
    balance,
    network?.name,
    disconnect,
    signMessage,
    updateSignature,
  ]);

  const openPopup = () => {
    setPopupOpen(!isPopupOpen);
    updatePopUpIsOpen(!isPopupOpen);
  };

  const [isOpenTestnet, setIsOpenTestnet] = useState(false);
  const handleTestnet = () => {
    setIsOpenTestnet(!isOpenTestnet);
  };

  const handleClickAway = () => {
    setIsOpenTestnet(false);
  };

  const handleCloseDepositModel = () => {
    setPopupOpen(false);
  };

  const handleClick = () => {
    navigate("/all-DApps");
  };

  const handlegoback = () => {
    navigate("/admin");
  };

  const handleNetworkSwitch = () => {
    const newNetwork =
      network?.name?.toLowerCase() === "mainnet" ? "testnet" : "mainnet";
    updateNetwork(newNetwork);
    toast.warning(
      `Please Switch the network from the wallet to ${newNetwork.toUpperCase()} network`,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }
    );
  };

  return (
    <div
      className={` bg-[#111213] h-[6rem] z-[1]  !fixed !top-0 flex flex-col justify-between items-start !font-inter   xxl:!rounded-[0_0_1rem_1rem] bxl:!rounded-[0_0_1rem_1rem] xl:!rounded-[0_0_1rem_1rem] sxl:!rounded-[0_0_1rem_1rem] lg:!rounded-[0rem] md:!rounded-[0rem] sm:!rounded-[0rem] xd:!rounded-[0rem]  xxl:!w-[1600px] bxl:!w-[1600px] xl:!w-[85%] sxl:!w-[90%] lg:!w-[96%] md:!w-[100%] sm:!w-[100%] xd:!w-[100%] `}
    >
      <div className="!h-24 flex flex-row  items-center justify-between  w-full xxl:!px-[1.5rem] bxl:!px-[1.5rem] xl:!px-[1.5rem] sxl:!px-[1.5rem] lg:!pr-[1.5rem] md:!pr-[0rem] sm:!pr-[0rem] xd:!pr-[0rem] ">
        <div className="xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:inline-flex md:inline-flex sm:inline-flex xd:inline-flex flex justify-between p-3">
          <img src={Kanalogo} alt="/Kanalogo" />
        </div>
        {changeHeader && checkIsAdmin ? (
          <div className=" flex justify-between w-full xxl:inline-flex xl:inline-flex sxl:inline-flex bxl:inline-flex lg:hidden md:hidden sm:hidden xd:hidden">
            <div
              className="w-[29%] flex gap-[0.5rem] justify-start items-center h-[3.5rem] px-[1rem] bg-[#1D1E20] rounded-[1rem] cursor-pointer"
              onClick={handlegoback}
            >
              <img src={LeftArrowGreen} alt="Greenright" />
              <div className="text-[#2ED3B7] font-[800] text-[0.875rem]   font-manrope">
                Go Back to Admin dashboard
              </div>
            </div>
            <div
              className="w-[29%] flex gap-[0.5rem] justify-center items-center h-[3.5rem] px-[1rem] bg-[#1D1E20] rounded-[1rem] cursor-pointer"
              onClick={handleClick}
            >
              <div className="text-[#2ED3B7] font-[800] text-[0.875rem]   font-manrope">
                All DApps
              </div>
            </div>
            <div className="w-[29%]  flex  items-center justify-start gap-[0.5rem]  h-[3.5rem] bg-[#1D1E20] rounded-[1rem] flex items-center justify-between p-3 ">
              <img
                className=" rounded-full w-5 h-5"
                src={wallet?.icon}
                alt="token"
              />
              <div className="text-[0.875rem] gap-[0.5rem] font-[800] text-[#2ED3B7]  ">
                {addressSlice(address)}
              </div>
              <span
                className="cursor-pointer"
                onClick={() => copyAddress(address)}
              ></span>
              <div className=" flex  items-center justify-start gap-[0.5rem] ">
                <img
                  src={Copy}
                  alt="Copy"
                  className="cursor-pointer w-4 h-4"
                  onClick={() => copyAddress(address)}
                />
                <img
                  src={Logout}
                  alt="Logout"
                  className=" cursor-pointer w-4 h-4  "
                  onClick={handleConnectDisconnect}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="  py-[1.5rem]  flex flex-row   gap-[1rem] !items-center !justify-start top-[0] w-full xxl:inline-flex bxl:inline-flex xl:inline-flex sxl:inline-flex lg:hidden md:hidden sm:hidden xd:hidden">
            <div
              className="w-[29%] flex gap-[0.5rem] justify-start items-center h-[3.5rem] px-[1rem] bg-[#1D1E20] rounded-[1rem] cursor-pointer"
              onClick={openPopup}
            >
              <img src={Switch} alt="Switch" />
              <div className="text-[#2ED3B7] font-[800] text-[0.875rem]   font-manrope">
                Add fund to fee-payer account
              </div>
            </div>
            <div className="w-[29%] h-[3.5rem] bg-[#1D1E20] rounded-[1rem] flex flex-row px-[1rem] justify-between items-center ">
              <div className="text-[#A5A5A6] font-[800] text-[0.875rem]">
                Wallet balance
              </div>
              <div className="text-[0.875rem] font-[800] text-[#2ED3B7]">
                {`${balance !== undefined ? `${balance} APT` : `${0} APT`}`}
              </div>
            </div>
            <div className="w-[29%]  flex  items-center justify-start gap-[0.5rem]  h-[3.5rem] bg-[#1D1E20] rounded-[1rem] flex items-center justify-between p-3 ">
              <img
                className=" rounded-full w-5 h-5"
                src={wallet?.icon}
                alt="token"
              />
              <div className="text-[0.875rem] gap-[0.5rem] font-[800] text-[#2ED3B7]  ">
                {addressSlice(address)}
              </div>
              <span
                className="cursor-pointer"
                onClick={() => copyAddress(address)}
              ></span>
              <div className=" flex  items-center justify-start gap-[0.5rem] ">
                <img
                  src={Copy}
                  alt="Copy"
                  className="cursor-pointer w-4 h-4"
                  onClick={() => copyAddress(address)}
                />
                <img
                  src={Logout}
                  alt="Logout"
                  className=" cursor-pointer w-4 h-4  "
                  onClick={handleConnectDisconnect}
                />
              </div>
            </div>
            <div className="w-[13%]">
              <div
                onClick={handleTestnet}
                className="w-full flex gap-[0.5rem] justify-between cursor-pointer items-center h-[3.5rem] px-[1rem] bg-[#1D1E20] rounded-[1rem] cursor-pointer"
              >
                <div className="flex flex-row justify-start items-center gap-[0.5rem]">
                  <img src={Container} alt="Container" />
                  <div className="text-[#2ED3B7] font-[800] text-[0.875rem] font-manrope">
                    {network?.name?.toLowerCase() === "mainnet"
                      ? "Mainnet"
                      : "Testnet"}
                  </div>
                </div>
                <img src={ChevronDownGreen} alt="ChevronDownGreen" />
              </div>
              {isOpenTestnet && (
                <div className="flex justify-center w-full">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="bg-[#0C0C0D] border-2 border-[#2e2f31] rounded-[1rem] h-auto absolute gap-[1rem] p-[0.75rem_1rem] flex flex-col gap-[0.5rem] justify-start items-start mt-2 text-[0.75rem] ">
                      <div className="cursor-pointer flex flex-row justify-start items-center gap-[0.5rem]">
                        <img src={Container} alt="Container" />
                        <div
                          className="text-[#2ED3B7] font-[800] text-[0.875rem] font-manrope"
                          onClick={handleNetworkSwitch}
                        >
                          {network?.name?.toLowerCase() === "mainnet"
                            ? "Testnet"
                            : "Mainnet"}
                        </div>
                      </div>
                    </div>
                  </ClickAwayListener>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:inline-flex md:inline-flex sm:inline-flex xd:inline-flex">
          <img src={Bell} alt="Bell" />
          <img src={Menubar} alt="Menubar" className=" ml-4 cursor-pointer" />
        </div>
      </div>
      {isPopupOpen && (
        <Deposit handleCloseDepositModel={handleCloseDepositModel} />
      )}
    </div>
  );
};

export default Header;
