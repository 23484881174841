import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import React, { ReactNode, useMemo } from "react";

export const useAptosContext = useWallet;
export const AptosWalletProvider = ({ children }: { children: ReactNode }) => {
  const wallets = useMemo(
    () =>
      [
        new PetraWallet(),
      ] as any,

    []
  );
  return (
    <AptosWalletAdapterProvider
      plugins={wallets}
      autoConnect={false}
      onError={(error: Error) => {
        console.log("wallet errors: ", error);
      }}
    >
      {children}
    </AptosWalletAdapterProvider>
  );
};

export default AptosWalletProvider;
